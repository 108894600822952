// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-abachi-jsx": () => import("./../../../src/pages/abachi.jsx" /* webpackChunkName: "component---src-pages-abachi-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-accounting-software-self-employed-jsx": () => import("./../../../src/pages/accounting-software-self-employed.jsx" /* webpackChunkName: "component---src-pages-accounting-software-self-employed-jsx" */),
  "component---src-pages-accounting-software-self-employed-soloplan-jsx": () => import("./../../../src/pages/accounting-software-self-employed-soloplan.jsx" /* webpackChunkName: "component---src-pages-accounting-software-self-employed-soloplan-jsx" */),
  "component---src-pages-blb-partner-jsx": () => import("./../../../src/pages/blb-partner.jsx" /* webpackChunkName: "component---src-pages-blb-partner-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-ebook-jsx": () => import("./../../../src/pages/ebook.jsx" /* webpackChunkName: "component---src-pages-ebook-jsx" */),
  "component---src-pages-einvoicing-australia-jsx": () => import("./../../../src/pages/einvoicing-australia.jsx" /* webpackChunkName: "component---src-pages-einvoicing-australia-jsx" */),
  "component---src-pages-einvoicing-government-jsx": () => import("./../../../src/pages/einvoicing-government.jsx" /* webpackChunkName: "component---src-pages-einvoicing-government-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-luca-pay-faq-jsx": () => import("./../../../src/pages/luca-pay/faq.jsx" /* webpackChunkName: "component---src-pages-luca-pay-faq-jsx" */),
  "component---src-pages-luca-pay-index-jsx": () => import("./../../../src/pages/luca-pay/index.jsx" /* webpackChunkName: "component---src-pages-luca-pay-index-jsx" */),
  "component---src-pages-luca-pay-signup-jsx": () => import("./../../../src/pages/luca-pay/signup.jsx" /* webpackChunkName: "component---src-pages-luca-pay-signup-jsx" */),
  "component---src-pages-market-place-jsx": () => import("./../../../src/pages/market-place.jsx" /* webpackChunkName: "component---src-pages-market-place-jsx" */),
  "component---src-pages-myob-jsx": () => import("./../../../src/pages/myob.jsx" /* webpackChunkName: "component---src-pages-myob-jsx" */),
  "component---src-pages-nsip-jsx": () => import("./../../../src/pages/nsip.jsx" /* webpackChunkName: "component---src-pages-nsip-jsx" */),
  "component---src-pages-partner-jsx": () => import("./../../../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-quick-books-disconnected-jsx": () => import("./../../../src/pages/QuickBooks-disconnected.jsx" /* webpackChunkName: "component---src-pages-quick-books-disconnected-jsx" */),
  "component---src-pages-quick-books-jsx": () => import("./../../../src/pages/QuickBooks.jsx" /* webpackChunkName: "component---src-pages-quick-books-jsx" */),
  "component---src-pages-sign-up-jsx": () => import("./../../../src/pages/sign-up.jsx" /* webpackChunkName: "component---src-pages-sign-up-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-starter-standard-lp-jsx": () => import("./../../../src/pages/starter-standard-lp.jsx" /* webpackChunkName: "component---src-pages-starter-standard-lp-jsx" */),
  "component---src-pages-starter-standard-pricing-page-jsx": () => import("./../../../src/pages/starter-standard-pricing-page.jsx" /* webpackChunkName: "component---src-pages-starter-standard-pricing-page-jsx" */),
  "component---src-pages-stratergic-business-partner-jsx": () => import("./../../../src/pages/stratergic-business-partner.jsx" /* webpackChunkName: "component---src-pages-stratergic-business-partner-jsx" */)
}

